const cssPrepareData = (ThemeColorJson, clientThemeName) => {
    //Theme Change Config.
    const { [clientThemeName]: themeColorObj = {} } = ThemeColorJson;
    const { primaryColor = '', searchIconColor = '', bannerBorderColor = '', defaultTextColor = '', labelColor = '', filterBgColor = ''
    } = themeColorObj;
    let root = document.querySelector(':root');
    root.style.setProperty('--primary-color', primaryColor)
    root.style.setProperty('--searchIcon-color', searchIconColor)
    root.style.setProperty('--bannerBorder-color', bannerBorderColor)
    root.style.setProperty('--defaultText-color', defaultTextColor)
    root.style.setProperty('--label-color', labelColor)
    root.style.setProperty('--filterBg-color', filterBgColor)
}

export default cssPrepareData;
