import React from 'react';
import '../../../css/components/_loader.less';

const EmptyLoader = () => {
    return (
        <div className="empty-wrapper">
            <div className="empty">
                <span className="box box-1"></span>
                <span className="box box-2"></span>
                <span className="box box-3"></span>
                <span className="box box-4"></span>
            </div>
        </div>
    )
}

export default EmptyLoader;