import React from "react";
import { connect } from "react-redux";
import { extend } from "lodash";

import { withRouter } from 'react-router';

import Constants from "./../constants";
import {
    LoginContainer, SewadarListingContainer,
    DashboardContainer, AddToWaitlistContainer,
    AttendanceContainer, DayWiseSewadarContainer,
    RequestSewaContainer, BadgeDownloadContainer,
    SewadarDownloadContainer, RecommendedSewadarContainer,
    CurrentAttendanceContainer, LinkboxContainer,
    BadgePrintContainer, SewadarProfileContainer,
    SewadarDirectoryContainer, MasterDownloadContainer
} from './LoadableContainer';
import Root from "components/Root/Root";

const RootContainer = props => <Root {...props} />;
const mapStateToProps = state => {
    const { router } = state;

    var urlMapping = {
        [Constants.routes.HOME]: LoginContainer,
        [Constants.routes.SEWADAR_LIST]: SewadarListingContainer,
        [Constants.routes.DASHBOARD]: DashboardContainer,
        [Constants.routes.ADD_TO_WAITLIST]: AddToWaitlistContainer,
        [Constants.routes.MASTER_DOWNLOAD]: MasterDownloadContainer,
        [Constants.routes.ATTENDANCE]: AttendanceContainer,
        [Constants.routes.DAY_WISE_SEWADAR]: DayWiseSewadarContainer,
        [Constants.routes.RECOMMENDED_SEWADAR]: RecommendedSewadarContainer,
        [Constants.routes.CURRENT_ATTENDANCE]: CurrentAttendanceContainer,
        [Constants.routes.REQUEST_SEWA]: RequestSewaContainer,
        [Constants.routes.BADGE_DOWNLOAD]: BadgeDownloadContainer,
        [Constants.routes.SEWADAR_DOWNLOAD]: SewadarDownloadContainer,
        [Constants.routes.LINKBOX]: LinkboxContainer,
        [Constants.routes.BADGE_PRINT]: BadgePrintContainer,
        [Constants.routes.SEWADAR_PROFILE]: SewadarProfileContainer,
        [Constants.routes.SEWADAR_DIRECTORY]: SewadarDirectoryContainer
    };
    var postQuoteList = {};

    /**
     * this assigns postQuoteList to urlMaping
     * and mutates urlMapping with postQuoteList property and propert in urlMapping
     *  having same key as postQuoteList will be overridden
     */
    urlMapping = extend(urlMapping, postQuoteList);

    return {
        paths: ['noPath'],
        routes: urlMapping,
        router
    };
};

const appContainer = withRouter(connect(
    mapStateToProps,
    null
)(RootContainer));

export default appContainer;
