/**
* Action Constants
*
* @description :: Actions for Redux used across application.
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

const actions = (() => {
    return {
        ROUTER: {
            CHANGE_ROUTE: 'ROUTER:CHANGE_ROUTE',
            RESET_STATE_DATA: 'ROUTER:RESET_STATE_DATA',
        },
        ERROR_HANDLER: {
            DISPLAY_ERROR_MESSAGE: 'ERROR_HANDLER:DISPLAY_ERROR_MESSAGE',
            HIDE_ERROR_MESSAGE: 'ERROR_HANDLER:HIDE_ERROR_MESSAGE',
            EMPTY_ERROR_MESSAGE: 'ERROR_HANDLER:EMPTY_ERROR_MESSAGE'
        },
        GLOBAL_ERROR_EVENT: 'GLOBAL_ERROR_EVENT',
        REMOVE_GLOBAL_ERROR_EVENT: 'REMOVE_GLOBAL_ERROR_EVENT',
        ACTIVE_COMPONENT: 'ACTIVE_COMPONENT',
        ACCESSREFRESH: 'ACCESSREFRESH',
        REQUEST_DATA: 'REQUEST_DATA',
        USER: {
            LOGIN: 'LOGIN',
            EVENT_LIST: 'EVENT_LIST',
            STATE_DATA: 'STATE_DATA',
            EMPTY_DATA: 'EMPTY_DATA'
        },
        SEWADAR: {
            LIST: 'LIST',
            FETCHING: 'FETCHING',
            ADD: 'ADD',
            MANAGE: 'MANAGE',
            FILTER_LIST: 'FILTER_LIST',
            ADDITIONAL_SEWADAR_REQUEST: 'ADDITIONAL_SEWADAR_REQUEST',
            EMPTY_LIST: 'EMPTY_LIST',
            UPLOAD_PHOTO: 'UPLOAD_PHOTO',
            UPLOAD_PHOTO_FETCHING: 'UPLOAD_PHOTO_FETCHING'
        },
        EVENT_SEWADAR: {
            LIST: 'EVENT_SEWADAR:LIST',
            FETCHING: 'EVENT_SEWADAR:FETCHING'
        },
        DASHBOARD: {
            FETCHING: 'FETCHING',
            REQUESTED: 'REQUESTED',
            WAITLIST: 'WAITLIST',
            WAITLIST_FETCHING: 'WAITLIST_FETCHING',
            PRINT_BADGE: 'PRINT_BADGE',
            APPROVE_SEWADAR_FETCHING: 'APPROVE_SEWADAR_FETCHING',
            APPROVE_SEWADAR: 'APPROVE_SEWADAR',
            MODULE_PARAMETER: 'MODULE_PARAMETER',
            EVENT_LOCATION: 'EVENT_LOCATION',
            MASTER_SEWADAR: 'MASTER_SEWADAR',
            MASTER_SEWADAR_FETCHING: 'MASTER_SEWADAR_FETCHING',
            ADD_NEW_SEWADAR: 'ADD_NEW_SEWADAR',
            ADD_NEW_SEWADAR_FETCHING: 'ADD_NEW_SEWADAR_FETCHING',
            ADD_NEW_EVENT_SEWADAR: 'ADD_NEW_EVENT_SEWADAR',
            ADD_NEW_EVENT_SEWADAR_FETCHING: 'ADD_NEW_EVENT_SEWADAR_FETCHING',
            CURRENT_ATTENDANCE: {
                GET: 'CURRENT_ATTENDANCE:GET',
                FETCHING: 'CURRENT_ATTENDANCE:FETCHING'
            },
            ISSUE_BADGE_FETCHING: 'DASHBOARD:ISSUE_BADGE_FETCHING',
            ISSUE_BADGE_RES: 'DASHBOARD:ISSUE_BADGE_RES',
            CLEAR_WAITLIST: 'DASHOBOARD: CLEAR_WAITLIST',
            BADGE_ISSUED: {
                FETCHING: 'BADGE_ISSUED:FETCHING',
                GET: 'BADGE_ISSUED:GET'
            }
        },
        ATTENDANCE: {
            MARK_ATTENDANCE: 'MARK_ATTENDANCE',
            FETCHING: 'FETCHING',
            ADHOC: 'ADHOC',
            ADHOC_FETCHING: 'ADHOC:FETCHING'
        },
        SEWA: {
            REQUEST_SEWA: 'REQUEST_SEWA',
            FETCHING: 'SEWA:FETCHING',
            OTP: 'OTP',
            OTP_FETCHING: 'OTP_FETCHING'
        },
        BADGE: {
            REQUEST_BADGE: 'REQUEST_BADGE',
            FETCHING: 'BADGE:FETCHING',
            OTP: 'BADGE:OTP',
            OTP_FETCHING: 'BADGE:OTP_FETCHING',
            GET_BADGE: 'BADGE:GET_BADGE',
            GET_BADGE_FETCHING: 'BADGE:GET_BADGE_FETCHING',
            GET_BADGE_INFO: 'BADGE:GET_BADGE_INFO'
        },
        DOWNLOAD: {
            SEWADAR: 'DOWNLOAD:SEWADAR',
            SEWADAR_FETCHING: 'DOWNLOAD:SEWADAR_FETCHING'
        }
    };
})();

export default actions;
