import React, { Component } from 'react';
import { connect } from "react-redux";
import APP from 'app';
import Constants from 'constants';
import translator from 'lib/translator';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
            open: false
        };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    static getDerivedStateFromProps = (nextProps) => {
        const { errorHandler = {} } = nextProps;
        const { errors = [] } = errorHandler;
        if (!_.isEmpty(errors)) {
            APP.Utils.showErrorMsg(errors);
            APP.MessageBus.trigger(Constants.actions.ERROR_HANDLER.HIDE_ERROR_MESSAGE, {
                success: false
            });
            return null;
        }
        return null;
    }

    render() {
        const imageUrl = 'https://sewadaar.bademandir.co.in/static/GKA.png';
        if (this.state.error) {
            return <div className="error__page__container">
                <center>
                    <div className="row error__page-height">
                        <img src={imageUrl} className="img-responsive" />
                    </div>
                    <div className="error__page__contnetbox">
                        <p className="error_page_paragraph">{translator({ textKey: '404Sorry' })}</p>
                        <a href="/" className="btn btn-warning error_button_homepage">{translator({ textKey: 'goToHome' })}</a>
                    </div>
                </center>
            </div>
        }
        return this.props.children;
    }
}

const mapStateToProps = state => {
    const { errorHandler = {} } = state;
    return {
        errorHandler
    }
};

export default connect(mapStateToProps, null)(ErrorBoundary);
