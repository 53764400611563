import { lazy } from 'react';

const LoginContainer = lazy(() => import('./LoginContainer'));
const SewadarListingContainer = lazy(() => import('./SewadarListingContainer'));
const DashboardContainer = lazy(() => import('./DashboardContainer'));
const AddToWaitlistContainer = lazy(() => import('./AddToWaitlistContainer'));
const MasterDownloadContainer = lazy(() => import('./MasterDownloadContainer'));
const AttendanceContainer = lazy(() => import('./AttendanceContainer'));
const DayWiseSewadarContainer = lazy(() => import('./DayWiseSewadarContainer'));
const RequestSewaContainer = lazy(() => import('./RequestSewaContainer'));
const BadgeDownloadContainer = lazy(() => import('./BadgeDownloadContainer'));
const SewadarDownloadContainer = lazy(() => import('./SewadarDownloadContainer'));
const RecommendedSewadarContainer = lazy(() => import('./RecommendedSewadarContainer'));
const CurrentAttendanceContainer = lazy(() => import('./CurrentAttendanceContainer'));
const LinkboxContainer = lazy(() => import('./LinkboxContainer'));
const BadgePrintContainer = lazy(() => import('./BadgePrintContainer'));
const SewadarProfileContainer = lazy(() => import('./SewadarProfileContainer'));
const SewadarDirectoryContainer = lazy(() => import('./SewadarDirectoryContainer'));

export {
    LoginContainer,
    SewadarListingContainer,
    DashboardContainer,
    AddToWaitlistContainer,
    AttendanceContainer,
    DayWiseSewadarContainer,
    RequestSewaContainer,
    BadgeDownloadContainer,
    SewadarDownloadContainer,
    RecommendedSewadarContainer,
    CurrentAttendanceContainer,
    LinkboxContainer,
    BadgePrintContainer,
    SewadarProfileContainer,
    SewadarDirectoryContainer,
    MasterDownloadContainer
};
