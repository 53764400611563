import constants from '../constants/actions';

const initialState = {
    userData: {},
    eventList: [],
    userStateData: {
        open: false,
        eventOpen: false,
        backDropOpen: false,
        showPassword: false,
        eventId: '',
        password: '',
        isAdmin: false
    }
};

export default function user(state = initialState, action) {
    try {
        const { type = '', payload = {} } = action;
        switch (type) {
            case constants.USER.LOGIN: {
                return {
                    ...state,
                    userData: payload
                };
            }
            case constants.USER.EVENT_LIST: {
                return {
                    ...state,
                    eventList: payload
                };
            }
            case constants.USER.STATE_DATA: {
                return {
                    ...state,
                    userStateData: payload
                };
            }
            case constants.USER.EMPTY_DATA: {
                return {
                    ...state,
                    userData: payload,
                    userStateData: initialState.userStateData
                };
            }
            default:
                return state;
        }

    } catch (error) {
        console.log('User Try catch Error ', error);
        return state;
    }
}
