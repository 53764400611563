/**
* App Index
*
* @description :: This is the way of having private object fields in JavaScript
* protecting pieces from leaking into the global scope and 
* accidentally colliding with another developer's interface
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

import Constants from 'constants';
import messageBus from './messageBus';
import utils from './utils';
import routerUtils from './routerUtils';

const APP = (() => {
    return {
        MessageBus: messageBus,
        Utils: utils,
        Constants: Constants,
        RouterUtils: routerUtils
    };
})();

export default APP;
