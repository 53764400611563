import constants from '../constants/actions';

const initialState = {
    fetching: false,
    badgeData: {},
    otpFetching: false,
    otpData: {},
    getBadgeData: '',
    getBadgeFetching: false,
    badgeInfo: []
};

export default function badge(state = initialState, action) {
    try {
        const { type = '', payload = {} } = action;
        switch (type) {
            case constants.BADGE.REQUEST_BADGE: {
                return {
                    ...state,
                    badgeData: payload,
                    fetching: false
                };
            }
            case constants.BADGE.FETCHING: {
                return {
                    ...state,
                    fetching: true
                };
            }
            case constants.BADGE.OTP: {
                return {
                    ...state,
                    otpData: payload,
                    otpFetching: false
                };
            }
            case constants.BADGE.OTP_FETCHING: {
                return {
                    ...state,
                    otpFetching: true
                };
            }
            case constants.BADGE.GET_BADGE: {
                return {
                    ...state,
                    getBadgeData: payload,
                    getBadgeFetching: false
                };
            }
            case constants.BADGE.GET_BADGE_FETCHING: {
                return {
                    ...state,
                    getBadgeFetching: true
                };
            }
            case constants.BADGE.GET_BADGE_INFO: {
                return {
                    ...state,
                    badgeInfo: payload
                };
            }
            default:
                return state;
        }

    } catch (error) {
        console.log('Badge Try catch Error ', error);
        return state;
    }
}
