import constants from '../constants/actions';

const initialState = {
    sewadarFetching: false,
    sewadarDump: ''
};

export default function download(state = initialState, action) {
    try {
        const { type = '', payload = {} } = action;
        switch (type) {
            case constants.DOWNLOAD.SEWADAR: {
                return {
                    ...state,
                    sewadarDump: payload,
                    sewadarFetching: false
                };
            }
            case constants.DOWNLOAD.SEWADAR_FETCHING: {
                return {
                    ...state,
                    sewadarFetching: true
                };
            }
            default:
                return state;
        }

    } catch (error) {
        console.log('Download Try catch Error ', error);
        return state;
    }
}
