import constants from '../constants/actions';

const initialState = {
    fetching: false,
    requestedSewadarJson: {},
    waitlistFetching: false,
    waitlistData: {},
    approveSewadarFetching: false,
    printBadgeUrl: '',
    approveSewadarRes: {},
    eventLocationData: [],
    moduleParameterData: [],
    masterSewadarData: {},
    masterSewadarFetching: false,
    addNewSewadarJson: null,
    addNewSewadarFetching: false,
    addNewEventSewadarJson: null,
    addNewEventSewadarFetching: false,
    recommendedSewadarData: [],
    recommendedSewadarFetching: false,
    currentAttendanceData: {},
    currentAttendanceFetching: false,
    issueBadgeFetching: false,
    issueBadgeRes: null,
    badgeIssuedFetching: false
};

export default function dashboard(state = initialState, action) {
    try {
        const { type = '', payload = {} } = action;
        switch (type) {
            case constants.DASHBOARD.REQUESTED: {
                return {
                    ...state,
                    requestedSewadarJson: payload,
                    fetching: false
                };
            }
            case constants.DASHBOARD.FETCHING: {
                return {
                    ...state,
                    fetching: payload
                };
            }
            case constants.DASHBOARD.WAITLIST: {
                return {
                    ...state,
                    waitlistData: payload,
                    waitlistFetching: false
                };
            }
            case constants.DASHBOARD.WAITLIST_FETCHING: {
                return {
                    ...state,
                    waitlistFetching: payload
                };
            }

            case constants.DASHBOARD.PRINT_BADGE: {
                return {
                    ...state,
                    printBadgeUrl: payload
                };
            }
            case constants.DASHBOARD.APPROVE_SEWADAR: {
                return {
                    ...state,
                    approveSewadarRes: payload,
                    approveSewadarFetching: false
                };
            }
            case constants.DASHBOARD.APPROVE_SEWADAR_FETCHING: {
                return {
                    ...state,
                    approveSewadarFetching: payload
                };
            }
            case constants.DASHBOARD.EVENT_LOCATION: {
                return {
                    ...state,
                    eventLocationData: payload
                };
            }
            case constants.DASHBOARD.MODULE_PARAMETER: {
                return {
                    ...state,
                    moduleParameterData: payload
                };
            }
            case constants.DASHBOARD.MASTER_SEWADAR: {
                return {
                    ...state,
                    masterSewadarData: payload,
                    masterSewadarFetching: false
                };
            }
            case constants.DASHBOARD.MASTER_SEWADAR_FETCHING: {
                return {
                    ...state,
                    masterSewadarFetching: payload
                };
            }
            case constants.DASHBOARD.ADD_NEW_SEWADAR: {
                return {
                    ...state,
                    addNewSewadarJson: payload,
                    addNewSewadarFetching: false
                };
            }
            case constants.DASHBOARD.ADD_NEW_SEWADAR_FETCHING: {
                return {
                    ...state,
                    addNewSewadarFetching: payload
                };
            }
            case constants.DASHBOARD.ADD_NEW_EVENT_SEWADAR: {
                return {
                    ...state,
                    addNewEventSewadarJson: payload,
                    addNewEventSewadarFetching: false
                };
            }
            case constants.DASHBOARD.ADD_NEW_EVENT_SEWADAR_FETCHING: {
                return {
                    ...state,
                    addNewEventSewadarFetching: payload
                };
            }
            case constants.DASHBOARD.CURRENT_ATTENDANCE.GET: {
                return {
                    ...state,
                    currentAttendanceData: payload,
                    currentAttendanceFetching: false
                };
            }
            case constants.DASHBOARD.CURRENT_ATTENDANCE.FETCHING: {
                return {
                    ...state,
                    currentAttendanceFetching: true
                };
            }
            case constants.DASHBOARD.ISSUE_BADGE_FETCHING: {
                return {
                    ...state,
                    issueBadgeFetching: payload,
                    issueBadgeRes: null
                };
            }
            case constants.DASHBOARD.ISSUE_BADGE_RES: {
                return {
                    ...state,
                    issueBadgeFetching: false,
                    issueBadgeRes: payload
                };
            }
            case constants.DASHBOARD.CLEAR_WAITLIST: {
                return {
                    ...state,
                    addNewSewadarJson: null,
                    masterSewadarData: {},
                    addNewEventSewadarJson: null
                };
            }
            case constants.DASHBOARD.BADGE_ISSUED.FETCHING: {
                return {
                    ...state,
                    badgeIssuedFetching: true
                };
            }
            case constants.DASHBOARD.BADGE_ISSUED.GET: {
                const { badgeIssuedCount = 0, badgeIssuedData = [], approvedCount = 0, approvedData = [] } = payload || {};
                return {
                    ...state,
                    badgeIssuedFetching: false,
                    waitlistData: {
                        ...state.waitlistData,
                        badgeIssuedCount,
                        badgeIssuedData,
                        approvedCount,
                        approvedData
                    }
                };
            }
            default:
                return state;
        }

    } catch (error) {
        console.log('Dashboard list Try catch Error ', error);
        return state;
    }
}
