import constants from '../constants/actions';

const initialState = {
    fetching: false,
    attendanceData: {},
    adhocFetching: false,
    adhocData: {}
};

export default function attendance(state = initialState, action) {
    try {
        const { type = '', payload = {} } = action;
        switch (type) {
            case constants.ATTENDANCE.MARK_ATTENDANCE: {
                return {
                    ...state,
                    attendanceData: payload,
                    fetching: false
                };
            }
            case constants.ATTENDANCE.FETCHING: {
                return {
                    ...state,
                    attendanceData: {},
                    fetching: payload
                };
            }
            case constants.ATTENDANCE.ADHOC: {
                return {
                    ...state,
                    adhocData: payload,
                    adhocFetching: false
                };
            }
            case constants.ATTENDANCE.ADHOC_FETCHING: {
                return {
                    ...state,
                    attendanceData: {},
                    adhocFetching: payload
                };
            }
            default:
                return state;
        }

    } catch (error) {
        console.log('Attendance list Try catch Error ', error);
        return state;
    }
}
