/**
* Constant Index
*
* @description :: exporting all constant files
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

import actions from './actions';
import routes from './routes';
import icons from './icons';
import apiErrors from './apiErrors';
import module from './module';

export default (() => {
    return {
        actions,
        routes,
        icons,
        apiErrors,
        module
    };
})();
