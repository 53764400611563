/**
* Action Constants
*
* @description :: Module wise constants used across application.
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

const modules = (() => {
    return {
        DEVELOPMENT: 'dev',
        EVENT: {
            ACCORDION_SWITCH: 'ACCORDION_SWITCH'
        },
        ROLE: {
            ADMIN: "ADMIN",
            CALLCENTER: "CALLCENTER",
            SUPERVISOR: "SUPERVISOR"
        }
    };
})();

export default modules;
