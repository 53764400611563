/**
* API Errors
*
* @description :: Constants used For Error handling Scenarios.
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

const apiErrors = (() => {
    return {
        100: 'Something went wrong!',
        400: 'Bad Request',
        403: 'Invalid Access. Send valid authorizarion token.Either token is invalid or its expired',
        502: 'Something went wrong with background server!'
    };
})();

export default apiErrors;
