import React, { Fragment, Component } from 'react';
import ErrorBoundary from 'components/Layout/ErrorBoundary';
import EmptyLoader from './../../views/Global/Loaders/EmptyLoader';
import cssPrepareData from './cssPrepare';
import Notifications from 'react-notify-toast';
import ThemeColorJson from './themeColor.json';
import Utils from './../../app/utils';
import Footer from '../../views/Global/footerTop';
import config from 'config';
import translator from 'lib/translator';

const { clientThemeName } = config;

const { errorToaster } = Utils;

class masterLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            online: true
        };
        this.flag = false;
    }

    setOnlineStatus = isOnline => this.setState({ online: isOnline })

    componentDidMount = () => {
        this.setState({ loader: true });
        cssPrepareData(ThemeColorJson, clientThemeName);
        window.addEventListener('online', () => this.setOnlineStatus(true));
        window.addEventListener('offline', () => this.setOnlineStatus(false));
    };

    render() {

        if (!this.state.online) { errorToaster(translator({ textKey: 'offline' })); this.flag = true };
        if (this.state.online && this.flag) { errorToaster(translator({ textKey: 'online' }), 'success'); this.flag = false };

        const { showFooter = false } = this.props;

        return (
            <Fragment>
                <ErrorBoundary>
                    <Notifications options={{ zIndex: 9999 }} />
                    {!this.state.loader ? <EmptyLoader /> :
                        <Fragment>
                            {this.props.children}
                        </Fragment>
                    }
                    {(showFooter) &&
                        <Footer />}
                </ErrorBoundary>
            </Fragment>
        )
    }

    componentWillUnmount() {
        window.removeEventListener('online');
        window.removeEventListener('offline');
    }

};

export default masterLayout;
