/**
* Action Constants
*
* @description :: Icons ids use it to map with iconid with filename in images/svg used across application.
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

const iconIds = (() => {
    return {
        COMMON: {

        }
    };
})();

export default iconIds;
