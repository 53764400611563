import constants from '../constants/actions';

const initialState = {
    fetching: false,
    sewaData: {},
    otpFetching: false,
    otpData: {}
};

export default function sewa(state = initialState, action) {
    try {
        const { type = '', payload = {} } = action;
        switch (type) {
            case constants.SEWA.REQUEST_SEWA: {
                return {
                    ...state,
                    sewaData: payload,
                    fetching: false
                };
            }
            case constants.SEWA.FETCHING: {
                return {
                    ...state,
                    fetching: true
                };
            }
            case constants.SEWA.OTP: {
                return {
                    ...state,
                    otpData: payload,
                    otpFetching: false
                };
            }
            case constants.SEWA.OTP_FETCHING: {
                return {
                    ...state,
                    otpFetching: true
                };
            }
            default:
                return state;
        }

    } catch (error) {
        console.log('Sewa Try catch Error ', error);
        return state;
    }
}
