/**
* Development
*
* @description :: all development configurations are kept in this file
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

const Config = {
    redirectionConfig: {
        incharge: 'list/sewadar',
        'back-office': 'list/sewadar',
        admin: 'linkbox',
        management: 'list/sewadar'
    },
    afterLogoutPath: '',
    basePath: '/',
    imgPath: 'https://sewadaar.bademandir.co.in/images/bmprod/images/',
    cookieDomain: process.env.COOKIE_DOMAIN || 'bademandir.co.in'
};

module.exports = Config;
