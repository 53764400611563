import React, { Component, Suspense } from 'react';
import APP from 'app';
import Constants from 'constants';
import { emitGlobalError, removeGlobalError, emptyErrorContainer } from 'actions/userActions';
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MasterLayout from 'components/Layout/MasterLayout';
import EmptyLoader from 'views/Global/Loaders/EmptyLoader';
import Error from '../Error/404';
import { access, excludedRoutes } from './../../accessControl';

class Root extends Component {

    constructor(props) {
        super(props);
        this.performScopeBindings();
    }

    performScopeBindings() {
        APP.MessageBus.on(Constants.actions.ERROR_HANDLER.DISPLAY_ERROR_MESSAGE, this.triggerGlobalError, this);
        APP.MessageBus.on(Constants.actions.ERROR_HANDLER.HIDE_ERROR_MESSAGE, this.triggerRemoveGlobalError, this);
    }

    triggerRemoveGlobalError(payload) {
        this.props.removeGlobalError(payload);
    }

    triggerGlobalError(payload) {
        this.props.removeGlobalError(payload);
        this.props.emitGlobalError(payload);
        setTimeout(() => { this.props.emptyErrorContainer() }, 500);
    }

    render() {
        const { router, routes, paths } = this.props,
            pathArr = window.location.pathname.split('/') || '';
        let path;
        if (pathArr[1] === '') {
        }
        if (2 < pathArr.length) {
            path = `${pathArr[1]}/${pathArr[2]}`;
        } else {
            path = window.location.pathname.split('/')[1];
        }

        const role = APP.Utils.getCookie('user_role');

        return (
            <div className="wrapper p-R">
                <MasterLayout showFooter={pathArr[1] ? false : true}>
                    <Suspense fallback={<EmptyLoader />}>
                        <Router>
                            <Switch>
                                {paths.map((pathMap, i) => {
                                    if (path in routes) {
                                        if (pathMap != "") {
                                            const Component = routes[path];
                                            if (access[role] && !access[role].includes(`/${path}`) && !excludedRoutes[`/${path}`]) {
                                                window.location.href = '/';
                                            }
                                            return <Component key={pathMap} {...this.props} />
                                        }
                                    } else {
                                        return <Route key={pathMap} component={Error} />
                                    }
                                })}
                            </Switch>
                        </Router>
                    </Suspense>
                </MasterLayout>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    removeGlobalError: (payload) => {
        dispatch(removeGlobalError(payload));
    },
    emitGlobalError: (payload) => {
        dispatch(emitGlobalError(payload));
    },
    emptyErrorContainer: (payload) => {
        dispatch(emptyErrorContainer(payload));
    }
});


export default connect(null, mapDispatchToProps)(Root);
