const excludedRoutes = {
    '/': true
};

const access = {
    admin: ['/list/sewadar', '/dashboard', '/dashboard/add-to-waitlist', '/dashboard/day-wise-sewadar', '/sewadar-download', '/dashboard/recommended-sewadar', '/dashboard/current-attendance', '/linkbox', '/badge-print', '/sewadar/profile', '/dashboard/sewadar-directory', '/dashboard/master-download'],
    management: ['/list/sewadar', '/dashboard', '/dashboard/recommended-sewadar', '/sewadar/profile', '/dashboard/current-attendance'],
    incharge: ['/list/sewadar', '/dashboard', '/attendance', '/dashboard/current-attendance', '/sewadar/profile', '/dashboard/add-to-waitlist', '/dashboard/sewadar-directory'],
    'back-office': ['/list/sewadar', '/dashboard', '/attendance', '/sewadar/profile', '/dashboard/add-to-waitlist']
};

export { access, excludedRoutes };
