import Constants from 'constants';

const initialState = {
    route: '',
};

const router = (state = initialState, action) => {
    try {
        switch (action.type) {
            case Constants.actions.ROUTER.CHANGE_ROUTE:
                return {
                    ...state,
                    route: action.route,
                }
            default:
                return state;
        }
    } catch (error) {
        return state;
    }
};

export default router;
