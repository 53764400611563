import constants from '../constants/actions';

const initialState = {
    masterList: [],
    list: [],
    availableCount: 0,
    fetching: false,
    manageRes: {},
    addSewadarRes: {},
    sewaCondition: false,
    sewadarlist: [],
    sewadarFetching: false,
    uploadedPhoto: {},
    uploadPhotoFetching: '',
    currentPage: 1, // Add currentPage to track pagination
    filterApplied: false
};

const updateSewadarList = (list, id, { data, avail }) => {
    if (data === 1) {
        list.forEach(item => {
            const { sewadar_id = '' } = item;
            if (sewadar_id === id) {
                item.availability = avail[0];
            }
        });
        return list;
    }
    return list;
};

export default function sewadarList(state = initialState, action) {
    try {
        const { type = '', payload = {} } = action;
        switch (type) {
            case constants.EVENT_SEWADAR.LIST: {
                const { list = [], availableCount = 0, condition = false } = payload;
                return {
                    ...state,
                    sewadarlist: list,
                    availableCount,
                    sewadarFetching: false
                };
            }
            case constants.EVENT_SEWADAR.FETCHING: {
                return {
                    ...state,
                    sewadarFetching: payload
                };
            }
            case constants.SEWADAR.LIST: {
                const { list = [], availableCount = 0, condition = false, page = 1 } = payload;
                const firstPage = page === 1;
                return {
                    ...state,
                    masterList: list,
                    list: firstPage ? list.slice(0, 10) : [...state.list, ...list.slice((page - 1) * 10, page * 10)],
                    availableCount,
                    fetching: false,
                    sewaCondition: condition,
                    currentPage: page
                };
            }
            case constants.SEWADAR.FETCHING: {
                return {
                    ...state,
                    fetching: payload
                };
            }
            case constants.SEWADAR.MANAGE: {
                const { sewadar_id = '', data = 0, availability = [], code = '' } = payload;
                if (code === 2) {
                    return {
                        ...state,
                        manageRes: payload
                    };
                }
                const masterList = updateSewadarList(state.masterList, sewadar_id, { data, avail: availability });
                const list = updateSewadarList(state.list, sewadar_id, { data, avail: availability });
                return {
                    ...state,
                    manageRes: payload,
                    masterList, list: list.slice(0, 10)
                };
            }
            case constants.SEWADAR.ADDITIONAL_SEWADAR_REQUEST: {
                return {
                    ...state,
                    addSewadarRes: payload
                };
            }
            case constants.SEWADAR.FILTER_LIST: {
                const { data, filterApplied } = payload;
                return {
                    ...state,
                    list: data.slice(0, 10),
                    filterApplied,
                    currentPage: filterApplied ? state.currentPage : 1
                };
            }
            case constants.SEWADAR.EMPTY_LIST: {
                return {
                    ...state,
                    list: payload.slice(0, 10),
                    masterList: payload,
                    filterApplied: false
                };
            }
            case constants.SEWADAR.UPLOAD_PHOTO_FETCHING: {
                return {
                    ...state,
                    uploadPhotoFetching: payload
                };
            }
            case constants.SEWADAR.UPLOAD_PHOTO: {
                return {
                    ...state,
                    uploadedPhoto: payload,
                    uploadPhotoFetching: ''
                };
            }
            default:
                return state;
        }

    } catch (error) {
        console.log('Sewadar list Try catch Error ', error);
        return state;
    }
}
