/**
* Translation Mapper
*
* @description :: Language Mapper
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

const translationJson = {
    // english mapper start
    poweredBy_IN_en: 'Powered By',
    noData_IN_en: 'No data found',
    checkAdmin_IN_en: 'Please, Check with administrator',
    rights_IN_en: 'All Right Reserved',
    gka_IN_en: 'GKA',
    successWaitlist_IN_en: 'Successfully added to waitlist',
    addWaitlist_IN_en: 'Add Sewadar In Waitlist',
    mobile_IN_en: 'Mobile',
    add_IN_en: 'Add',
    waitlistSearch_IN_en: 'Search. Either add a new sewadar or',
    addNew_IN_en: 'Add New Sewadar',
    name_IN_en: 'Name',
    email_IN_en: 'Email',
    gender_IN_en: 'Gender',
    none_IN_en: 'None',
    male_IN_en: 'Male',
    female_IN_en: 'Female',
    reference_IN_en: 'Reference',
    comments_IN_en: 'Comments',
    submit_IN_en: 'Submit',
    sewaCode_IN_en: 'Sewa Code',
    role_IN_en: 'Role',
    stop_IN_en: 'Stop',
    scan_IN_en: 'Scan',
    badgeNo_IN_en: 'Badge No.',
    responsibility_IN_en: 'Responsibility',
    sewaLocation_IN_en: 'Sewa Location',
    sewadarName_IN_en: 'Sewadar Name',
    approvedSewadars_IN_en: 'Approved Sewadars',
    print_IN_en: 'Print',
    badgeIssuedSewadars_IN_en: 'Badge Issued Sewadars',
    copyright_IN_en: 'Copyright',
    hello_IN_en: 'Hello',
    dashboard_IN_en: 'Dashboard',
    list_IN_en: 'List',
    directory_IN_en: 'Directory',
    attendance_IN_en: 'Attendance',
    addToWaitlist_IN_en: 'Add to waitlist',
    logout_IN_en: 'Logout',
    reqSewadars_IN_en: 'Requested Sewadars',
    waitlistSewadars_IN_en: 'Waitlist Sewadars',
    requestedBy_IN_en: 'Requested By',
    locationNumber_IN_en: 'Location Number',
    waitlistComments_IN_en: 'Please add Comments and select location and status !!!',
    updateSuccess_IN_en: 'Updated Successfully !!!',
    location_IN_en: 'Location',
    status_IN_en: 'Status',
    '404Sorry_IN_en': 'Sorry, we could not find the resource you are looking for,',
    goToHome_IN_en: 'Go to homepage',
    offline_IN_en: 'You are offline',
    online_IN_en: 'Back online',
    loginValid_IN_en: 'Please fill Login Id and Password',
    selectEvent_IN_en: 'Please select an event',
    loginId_IN_en: 'Login Id',
    password_IN_en: 'Password',
    event_IN_en: 'Event',
    admin_IN_en: 'Admin',
    weekly_IN_en: 'Weekly',
    login_IN_en: 'Log In',
    applyFilter_IN_en: 'Apply Filter',
    apply_IN_en: 'Apply',
    addSuccess_IN_en: 'Added Successfully !!!',
    outOfStation_IN_en: 'Out of station',
    unwell_IN_en: 'Unwell',
    officialWork_IN_en: 'Official Work',
    others_IN_en: 'Others',
    noResponse_IN_en: 'No Response',
    phoneNotReachable_IN_en: 'Phone not reachable',
    sewadarNeedTimeToConfirm_IN_en: 'Sewadar need time to confirm',
    allLocations_IN_en: 'All Locations',
    eventSewadars_IN_en: 'Event Sewadars',
    totalSewadars_IN_en: 'Total Sewadars',
    sewadars_IN_en: 'Sewadars',
    availableSewadars_IN_en: 'Available Sewadars',
    selectAddComments_IN_en: 'Please select/add some comments !!!',
    nonAvailReason_IN_en: 'Reason for Non Availability of sewadar',
    nonConfirmReason_IN_en: 'Reason for Non Confirmation of sewadar',
    recommendReason_IN_en: 'Recommendation',
    recommendSewadar_IN_en: 'Recommended Sewadars',
    selectReason_IN_en: 'Select Reason',
    addComments_IN_en: 'Add Comments',
    noOfSewadars_IN_en: 'Number of sewadar(s)',
    number_IN_en: 'Number',
    plAddNumber_IN_en: 'Please add number of sewadars less than 100',
    notAvail_IN_en: 'Not Available',
    yetToConfirm_IN_en: 'Yet to confirm',
    avail_IN_en: 'Available',
    sewaArea_IN_en: 'Sewa Area',
    sewaDay_IN_en: 'Sewa Day (Pre-COVID)',
    age_IN_en: 'Age',
    boIn_IN_en: 'BO (In)',
    boOut_IN_en: 'BO (Out)',
    inchIn_IN_en: 'Inch (In)',
    inchOut_IN_en: 'Inch (Out)',
    filter_IN_en: 'Filter',
    reset_IN_en: 'Reset',
    provideVal_IN_en: 'Please Provide a value',
    emailValid_IN_en: 'Please enter valid Email',
    mobileValid_IN_en: 'Please enter valid Mobile Number',
    actions_IN_en: 'Actions',
    referedBy_IN_en: 'Refered By',
    addedByName_IN_en: 'Added By name',
    addedBy_IN_en: 'Added By',
    sewadarStatus_IN_en: 'Sewadar Status',
    controlRoom_IN_en: 'Control Room',
    daywiseSewadar_IN_en: 'Day Wise Sewadars',
    monday_IN_en: 'Monday',
    thursday_IN_en: 'Thursday',
    friday_IN_en: 'Friday',
    saturday_IN_en: 'Saturday',
    sunday_IN_en: 'Sunday',
    daywise_IN_en: 'Day Wise',
    function_IN_en: 'Function',
    back_IN_en: 'Back',
    barcode_IN_en: 'Barcode',
    qrcode_IN_en: 'QR Code',
    selectRecommendation_IN_en: 'Select Recommendation',
    currentAttendance_IN_en: 'Current Attendance',
    issueBadge_IN_en: 'Issue Badge',
    // english mapper end

    // hindi mapper start
    poweredBy_IN_hi: 'द्वारा संचालित',
    noData_IN_hi: 'क्षमा करें, कोई डेटा नहीं मिला',
    checkAdmin_IN_hi: 'कृपया, व्यवस्थापक से जाँच करें',
    rights_IN_hi: 'सर्वाधिकार सुरक्षित',
    gka_IN_hi: 'GKA',
    successWaitlist_IN_hi: 'वेटलिस्ट में सफलतापूर्वक जोड़ा गया',
    addWaitlist_IN_hi: 'सेवडलर इन वेटलिस्ट जोड़ें',
    mobile_IN_hi: 'मोबाइल',
    add_IN_hi: 'जोड़ना',
    waitlistSearch_IN_hi: 'खोज। या तो एक नया सिलाई जोड़ें या',
    addNew_IN_hi: 'नया सेवादार जोड़ें',
    name_IN_hi: 'नाम',
    email_IN_hi: 'ईमेल',
    gender_IN_hi: 'लिंग',
    none_IN_hi: 'कोई नहीं',
    male_IN_hi: 'पुरुष',
    female_IN_hi: 'महिला',
    reference_IN_hi: 'संदर्भ',
    comments_IN_hi: 'टिप्पणियाँ',
    submit_IN_hi: 'प्रस्तुत',
    sewaCode_IN_hi: 'सेवा संहिता',
    role_IN_hi: 'भूमिका',
    stop_IN_hi: 'रुकें',
    scan_IN_hi: 'स्कैन',
    badgeNo_IN_hi: 'भबैज नं।',
    responsibility_IN_hi: 'ज़िम्मेदारी',
    sewaLocation_IN_hi: 'सेवा स्थान',
    sewadarName_IN_hi: 'सेवादार नाम',
    approvedSewadars_IN_hi: 'स्वीकृत सेवादार',
    print_IN_hi: 'छाप',
    badgeIssuedSewadars_IN_hi: 'बिल्ला जारी सेवादारों',
    copyright_IN_hi: 'कॉपीराइट',
    hello_IN_hi: 'नमस्कार',
    dashboard_IN_hi: 'डैशबोर्ड',
    list_IN_hi: 'सूची',
    directory_IN_hi: 'निर्देशिका',
    attendance_IN_hi: 'उपस्थिति',
    addToWaitlist_IN_hi: 'वेटलिस्ट में जोड़ें',
    logout_IN_hi: 'लॉग आउट',
    reqSewadars_IN_hi: 'आवश्यक सेवादार',
    waitlistSewadars_IN_hi: 'वेटलिस्ट सेवादार',
    requestedBy_IN_hi: 'द्वारा अनुरोध किया गया',
    locationNumber_IN_hi: 'स्थान संख्या',
    waitlistComments_IN_hi: 'कृपया टिप्पणियाँ जोड़ें और स्थान और स्थिति का चयन करें !!!',
    updateSuccess_IN_hi: 'अद्यतन सफलतापूर्ण हो गया !!!',
    location_IN_hi: 'स्थान',
    status_IN_hi: 'स्थिति',
    '404Sorry_IN_hi': 'क्षमा करें, हमें वह संसाधन नहीं मिला जिसकी आप तलाश कर रहे हैं,',
    goToHome_IN_hi: 'मुखपृष्ठ प्र जाएं',
    offline_IN_hi: 'आप ऑफलाइन हैं',
    online_IN_hi: 'वापस ऑनलाइन',
    loginValid_IN_hi: 'कृपया लॉगिन आईडी और पासवर्ड भरें',
    selectEvent_IN_hi: 'कृपया एक ईवेंट चुनें',
    loginId_IN_hi: 'लॉगिन आईडी',
    password_IN_hi: 'कुंजिका',
    event_IN_hi: 'प्रतिस्पर्धा',
    admin_IN_hi: 'व्यवस्थापक',
    login_IN_hi: 'लॉग इन करें',
    applyFilter_IN_hi: 'फिल्टर लागू करें',
    apply_IN_hi: 'लागू',
    addSuccess_IN_hi: 'सफलतापूर्वक जोड़ा गया !!!',
    outOfStation_IN_hi: 'स्टेशन से बाहर',
    unwell_IN_hi: 'अस्वस्थ',
    officialWork_IN_hi: 'आधिकारिक कार्य',
    others_IN_hi: 'अन्य',
    noResponse_IN_hi: 'कोई जवाब नहीं',
    phoneNotReachable_IN_hi: 'फोन नहीं पहुंच पा रहा है',
    sewadarNeedTimeToConfirm_IN_hi: 'सेवादार को पुष्टि के लिए समय चाहिए',
    allLocations_IN_hi: 'समस्त स्थान',
    eventSewadars_IN_hi: 'घटना सेवादारों',
    totalSewadars_IN_hi: 'कुल सेवादार',
    sewadars_IN_hi: 'सेवादार',
    availableSewadars_IN_hi: 'उपलब्ध सेवादार',
    selectAddComments_IN_hi: 'कृपया कुछ टिप्पणियों का चयन करें / जोड़ें !!!',
    nonAvailReason_IN_hi: 'सेवादार की अनुपलब्धता का कारण',
    nonConfirmReason_IN_hi: 'सेवादार की गैर पुष्टि का कारण',
    recommendReason_IN_hi: 'सिफारिश का कारण',
    recommendSewadar_IN_hi: 'अनुशंसित सेवादार',
    selectReason_IN_hi: 'कारण चुनें',
    addComments_IN_hi: 'टिप्पणी करें',
    noOfSewadars_IN_hi: 'सेवादार की संख्या',
    number_IN_hi: 'संख्या',
    plAddNumber_IN_hi: 'कृपया 100 से कम सेवादारों की संख्या जोड़ें',
    notAvail_IN_hi: 'उपलब्ध नहीं है',
    yetToConfirm_IN_hi: 'फिर भी पुष्टि करने के लिए',
    avail_IN_hi: 'उपलब्ध',
    sewaArea_IN_hi: 'सेवा क्षेत्र',
    sewaDay_IN_hi: 'सेवा दिवस (पूर्व COVID)',
    age_IN_hi: 'उम्र',
    boIn_IN_hi: 'बीओ (में)',
    boOut_IN_hi: 'बीओ (आउट)',
    inchIn_IN_hi: 'इंच (में)',
    inchOut_IN_hi: 'इंच (बाहर)',
    filter_IN_hi: 'फ़िल्टर',
    reset_IN_hi: 'रीसेट',
    provideVal_IN_hi: 'कृपया एक मूल्य प्रदान करें',
    emailValid_IN_hi: 'कृपया वैध ईमेल दर्ज़ करें',
    mobileValid_IN_hi: 'कृपया मान्य मोबाइल नंबर दर्ज करें',
    actions_IN_hi: 'क्रिया',
    referedBy_IN_hi: 'द्वारा उल्लिखित',
    addedByName_IN_hi: 'नाम से जोड़ा गया',
    addedBy_IN_hi: 'द्वारा जोड़ा',
    sewadarStatus_IN_hi: 'सेवादार की स्थिति',
    controlRoom_IN_hi: 'नियंत्रण कक्ष',
    daywiseSewadar_IN_hi: 'दिन समझदार सेवादार',
    monday_IN_hi: 'सोमवार',
    thursday_IN_hi: 'गुरूवार',
    friday_IN_hi: 'शुक्रवार',
    saturday_IN_hi: 'शनिवार',
    sunday_IN_hi: 'रविवार',
    daywise_IN_hi: 'दिन के हिसाब से',
    function_IN_hi: 'समारोह',
    back_IN_hi: 'वापस',
    barcode_IN_hi: 'बारकोड',
    qrcode_IN_hi: 'क्यूआर कोड',
    weekly_IN_hi: 'वीक्‌लि',
    selectRecommendation_IN_hi: 'सिफारिश का चयन करें',
    currentAttendance_IN_hi: 'वर्तमान उपस्थिति',
    issueBadge_IN_hi: 'इश्यू बैज',
    // hindi mapper end
};

export default translationJson;
