import { combineReducers } from 'redux';
import user from './user';
import errorHandler from './errorHandler';
import sewadarList from './sewadarList';
import router from './routerReducer';
import dashboard from './dashboard';
import attendance from './attendance';
import sewa from './sewa';
import badge from './badge';
import download from './download';

const rootReducer = combineReducers({
    errorHandler,
    user,
    sewadarList,
    router,
    dashboard,
    attendance,
    sewa,
    badge,
    download
});

export default rootReducer;
