/**
* User Action
*
* @description :: file for all the user actions
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

import Constants from 'constants';
import { isEmpty } from 'lodash';

const emitGlobalError = (payload) => {
    return {
        type: Constants.actions.ERROR_HANDLER.DISPLAY_ERROR_MESSAGE,
        payload: payload
    };
},

    emptyErrorContainer = (payload) => {
        return {
            type: Constants.actions.ERROR_HANDLER.EMPTY_ERROR_MESSAGE,
            payload: payload
        }
    },

    removeGlobalError = (payload) => {
        return async (dispatch, getState) => {
            if (!isEmpty(getState().errorHandler.errors)) {
                return {
                    type: Constants.actions.ERROR_HANDLER.HIDE_ERROR_MESSAGE,
                    payload: payload
                };
            }

        }
    };

export { emitGlobalError, removeGlobalError, emptyErrorContainer };
