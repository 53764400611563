import React from 'react';
import '../../css/modules/_footer.less';
import translator from 'lib/translator';

const FooterTop = () => {

    return (
        <div className='footertop'>
            <div className='app__container '>
                <div className='footertop__left'>
                    <p className='margin__zero font__white'>{translator({ textKey: 'gka' })} &copy; {new Date().getFullYear()} || {translator({ textKey: 'rights' })}</p>
                </div>
                <div className='footertop__right'>
                    <h3 className='footertop__left--heading margin__zero font__white'>
                        <a className='footertop__left--linkheding' target='_blank'>{translator({ textKey: 'poweredBy' })} {translator({ textKey: 'gka' })}</a>
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default FooterTop;
