import React from 'react';
import translator from 'lib/translator';

const Error = (props) => {
    const imageUrl = 'https://sewadaar.bademandir.co.in/static/GKA.png';

    return (
        <div className="error__page__container">
            <center>
                <div className="row error__page-height">
                    <img src={imageUrl} className="img-responsive" />
                </div>
                <div className="error__page__contnetbox">
                    <p className="error_page_paragraph" style={{ marginBottom: '10px' }}>{translator({ textKey: '404Sorry' })}</p>
                    <a href="/" className="btn btn-warning error_button_homepage">{translator({ textKey: 'goToHome' })}</a>
                </div>
            </center>
        </div>
    );
};

export default Error;
