/**
* Action Constants
*
* @description :: Application route file
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

const routes = (() => {
        return {
                HOME: '',
                SEWADAR_LIST: 'list/sewadar',
                DASHBOARD: 'dashboard',
                ADD_TO_WAITLIST: 'dashboard/add-to-waitlist',
                ATTENDANCE: 'attendance',
                DAY_WISE_SEWADAR: 'dashboard/day-wise-sewadar',
                REQUEST_SEWA: 'request-sewa',
                BADGE_DOWNLOAD: 'badge-download',
                SEWADAR_DOWNLOAD: 'sewadar-download',
                RECOMMENDED_SEWADAR: 'dashboard/recommended-sewadar',
                CURRENT_ATTENDANCE: 'dashboard/current-attendance',
                LINKBOX: 'linkbox',
                BADGE_PRINT: 'badge-print',
                SEWADAR_PROFILE: 'sewadar/profile',
                SEWADAR_DIRECTORY: 'dashboard/sewadar-directory',
                MASTER_DOWNLOAD: 'dashboard/master-download',
        };
})();

export default routes;
