/**
* Main File
*
* @description :: Main file (entry point for the application)
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppContainer from 'containers/AppContainer';
import configureStore from 'store/configureStore';
import { initializeApp } from './../sw';
import Cookie from "react-cookies";
import config from 'config';
import { access, excludedRoutes } from './accessControl';

require('../src/css/main.less');

const isAuthenticated = () => {
    const { basePath = '/' } = config;
    if (Cookie.load('tokenID') == undefined && !excludedRoutes[location.pathname]) {
        window.location.href = basePath;
    }
    const role = Cookie.load('user_role') || '';
    if (access[role] && !access[role].includes(location.pathname) && !excludedRoutes[location.pathname]) {
        window.location.href = basePath;
    }
    return <AppContainer />;
};


/* global document */

const { store } = configureStore();

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                {isAuthenticated()}
                <div className="overlay__wrapper"></div>
            </BrowserRouter>
        </Provider>
    </StrictMode>,
    document.getElementById('app')
);

initializeApp();
