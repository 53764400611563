/**
* Translation Index
*
* @description :: File used for multi language support
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

import APP from 'app';
import translationJson from './translationMapper';

const getCountryCode = () => {
    return APP.Utils.getLocalStorage('ccode') || 'IN';
};

const getLocale = () => {
    return APP.Utils.getLocalStorage('locale') || 'en';
};

const Translator = props => {
    const ccode = getCountryCode();
    const locale = getLocale();
    const extraText = props.extraText ? props.extraText : '';
    const textKey = `${props.textKey}_${ccode}_${locale}`;
    const textToReturn = translationJson[textKey] + extraText;
    return textToReturn;
};

export default Translator;
