/**
* Utils
*
* @description :: This utility is used for have the common feature functions which will use in
* the application like JWT, formatClass or others
* @author Chahat Bahl
* @date 3 Mar, 2020
*/

import configObj from 'config';
import _ from 'lodash';
import moment from 'moment';
import { notify } from 'react-notify-toast';

class Utils {

    formatTime = (time) => {
        return moment(time, "HH:mm:ss").format("hh:mm A");
    }


    setCookie = (name, value, min, domain = '', expiry = '') => {
        let expires = "; expires=" + expiry;
        if (min) {
            let date = new Date();
            date.setTime(date.getTime() + (min * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        let primaryDomain = "; domain=" + domain;

        document.cookie = name + "=" + value + expires + primaryDomain + "; path=/";
    }

    expiredTime = () => {
        const currentDate = moment();
        const futureMonth = moment(currentDate).subtract(1, 'M').format('x');

        return new Date(Number(futureMonth)).toGMTString();
    }


    cookieExpiryTime = () => {
        const currentDate = moment();
        const futureMonth = moment(currentDate).add(1, 'M').format('x');

        return new Date(Number(futureMonth)).toGMTString();
    }

    isMobile = () => {
        return 768 > document.documentElement.clientWidth;
    }

    saveToCookie = (name, value) => {
        const expires = this.cookieExpiryTime(),
            { cookieDomain = 'bademandir.co.in' } = configObj;
        this.setCookie(name, value, '', cookieDomain, expires);
    }

    isDayWiseSewa = () => {
        let isDayWiseSewa = this.getCookie('isDaywiseSewa');
        isDayWiseSewa = isDayWiseSewa === 'true';
        return isDayWiseSewa;
    }

    removeCookie = (name) => {
        const expires = this.expiredTime();
        const { cookieDomain = 'bademandir.co.in' } = configObj;
        this.setCookie(name, '', '', cookieDomain, expires);
    }

    getCookie = (name) => {
        const nameEQ = `${name}=`,
            ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i = i + 1) {
            let c = ca[i];
            while (' ' === c.charAt(0)) {
                c = c.substring(1, c.length);
            }
            if (0 === c.indexOf(nameEQ)) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    /***This function scrolls to top of window*/
    scrollToTop = () => {
        return window.scrollTo(0, 0);
    }

    /**
     * @param {string} searchUrl
     * @return {object} queryKey and Value
     */
    extractKeysFromUrl = (searchUrl) => {
        const searchUrlString = decodeURIComponent(searchUrl);
        return searchUrlString.replace('?', '').split('&').reduce((searchQuery, query) => {
            const splitQuery = query.split('='),
                queryKey = splitQuery[0],
                queryValue = splitQuery[1];
            return {
                ...searchQuery,
                [queryKey]: queryValue
            };
        }, {});
    };

    isEmpty = (obj) => {
        if (_.isUndefined(obj) || obj == 0 || obj == '0'
            || obj === false || obj == null) {
            return true;
        }
        if (_.isObject(obj) && _.size(obj) === 0) {
            return true;
        }
        if (_.isNumber(obj) || _.isBoolean(obj) || _.isDate(obj)
            || (_.isObject(obj) && _.size(obj) > 0)) {
            return false;
        }
        return _.isEmpty(obj);
    };

    getLastIdFromUrl = () => {
        const url = window.location.pathname;
        return url.substring(url.lastIndexOf('/') + 1);
    };

    formatTime = (time, type = 'HH:mm') => {
        return moment(time, 'HH:mm:ss').format(type);
    };

    formatDate = (date, type = 'MMM D HH:mm') => {
        return moment(date).format(type);
    };

    dateDiff = (d1, d2) => {
        const date2 = new Date(d1),
            date1 = new Date(d2),
            timeDiff = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    };

    /*======ProperCase =========*/
    lowerCase = (str) => {
        return str.toLowerCase();
    };

    upperCase = (str) => {
        return str.toUpperCase();
    };

    properCase = (str = '') => {
        const removeUnderscore = str.replace(/(\w)(_)(\w)/g, '$1 $3');
        return utils.lowerCase(removeUnderscore).replace(/^\w|\s\w/g, utils.upperCase);
    };

    removeHTMLTags = (str = '') => {
        return str.replace(/(&nbsp;|&lt;|&gt;|&amp;|\n|\r|<([^>]+)>)/ig, '');
    };

    removeEmptyKeysFromObject = (obj) => {
        Object.keys(obj).forEach(key => {
            if (Object.prototype.toString.call(obj[key]) === '[object Date]' && (obj[key].toString().length === 0 || obj[key].toString() === 'Invalid Date')) {
                delete obj[key];
            } else if (obj[key] && typeof obj[key] === 'object') {
                utils.removeEmptyKeysFromObject(obj[key]);
            } else if (obj[key] == null || obj[key] === '') {
                delete obj[key];
            }

            if (obj[key]
                && typeof obj[key] === 'object'
                && Object.keys(obj[key]).length === 0
                && Object.prototype.toString.call(obj[key]) !== '[object Date]') {
                delete obj[key];
            }
        });
        return obj;
    };

    /**
     * @description empty session storage
     * @param specific keys array
     * @requires keys in array
     */
    emptySessionStorage = (keys) => {
        return keys.forEach(element => {
            return sessionStorage.removeItem(element);
        });
    };

    getFromSession = (defaultVal, key) => {
        let storage = defaultVal;
        try {
            storage = JSON.parse(sessionStorage.getItem(key));
        } catch (error) {
            // check with this log it
            return defaultVal;
        };
        return storage;
    };

    setSession = (key, value) => {
        sessionStorage.setItem(key, JSON.stringify(value));
    };

    redirect = (path) => {
        window.location.href = path;
    };

    roleWisePermissions = (roles) => { // array of roles allowed
        const userRole = this.getCookie('user_role');
        return roles.includes(userRole);
    };

    setSearchSession(searchIds) {
        const { sessionExpireTimeLimit = 18, EnableSessionTime = false } = configObj;
        if (searchIds.length > 0 && EnableSessionTime) {
            searchIds.forEach((searchId) => {
                const sessionTime = moment().add(sessionExpireTimeLimit, "minutes").format('MM/DD/YYYY HH:mm');
                localStorage.setItem(`lastSearchTime_${searchId}`, sessionTime)
                localStorage.setItem(`lastSearchQuery_${searchId}`, window.location.href);
            });
        }


    };
    removeSearchSession(searchIds) {
        const items = { ...localStorage };
        if (searchIds.length > 0) {
            Object.keys(items).length && Object.keys(items).forEach(item => {
                const lastSearchTime = items[item];
                if (item && (/^lastSearch/.test(item)) && this.getSearchSession(lastSearchTime) < 1) {
                    const [str, id] = item.split("_");
                    if (!searchIds.includes(id)) {
                        localStorage.removeItem(item);
                        localStorage.removeItem(`lastSearchQuery_${id}`);
                    }
                }
            });
        }
    };

    getSearchSession(getLastSearchTime) {
        const start = moment();
        const end = moment(getLastSearchTime);
        const elapsedMinutes = end.diff(start, "minutes");
        return elapsedMinutes;
    };

    setLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getLocalStorage = (key) => {
        return JSON.parse(localStorage.getItem(key));
    }

    deleteLocalStorage = (key) => {
        localStorage.removeItem(key);
    };

    /**
  * @description generic error displaying function
  * @param errors
  * @returns toast err msg
  */
    showErrorMsg = (errors = []) => {
        const [firstErr = {}] = errors,
            { message = '' } = firstErr;

        return this.errorToaster(message);
    };

    errorToaster = (msg, msgType = 'error', timePeriod = 3000) => {
        notify.show(msg, msgType, timePeriod);
    };

    locationNumber = (location_no) => {
        return location_no;
        // return location_no <= 9 ? `0${location_no}` : location_no;
    };

    checkSameUrl = (currentPath) => {
        return currentPath === location.pathname;
    };

    deleteValueInArray = (arr, val) => {
        const index = arr.indexOf(val);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    deepClone = (payload) => JSON.parse(JSON.stringify(payload));

    specialPermmission = () => {
        const username = this.getCookie('username');
        return ['chahat'].includes(username);
    }

    currentAttendancePermission = () => {
        const username = this.getCookie('username');
        return ['chahat', 'kunal', 'sankalp', 'admin2'].includes(username);
    }

    snooze = ms => new Promise(resolve => setTimeout(resolve, ms));

}
const utils = new Utils();

Object.freeze(utils);

export default utils;
